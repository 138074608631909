<template>
  <div class="px-[2px]">
    <div v-if="!loading" class="rf-flex rf-flex-col rf-w-full rf-max-w-[1140px] rf-mx-auto rf-py-10 rf-gap-4">
      <div class="rf-text-[32px] rf-text-[#0f4379] rf-font-medium rf-mb-5">
        <span v-if="todayData">{{ $t("Files Currently Under Processing by Our Engineers") }}</span>
        <span v-else>{{ $t("Yesterday processed files") }}</span>
      </div>
      <div v-for="(vehicle, index) in vehicles" :key="index" class="rf-relative rf-mb-1">
        <div
          class="rf-grid screen1100:rf-grid-cols-[75px_410px_1fr] rf-grid-rows-[1fr] rf-z-20 rf-max-w-[98%] rf-mx-auto rf-bg-[#ffffff] rf-shadow-md rf-rounded-xl rf-items-center rf-pb-1 screen1100:rf-pb-0">
          <div class="rf-flex rf-justify-center screen1100:rf-justify-normal rf-py-1 screen1100:rf-py-2 rf-px-3 rf-border-r">
            <img :src="getBrandImageUrl(vehicle.vehicle_data.brand)" :alt="vehicle.vehicle_data.brand" class="rf-w-[100px] rf-w-h-[100px] screen1100:rf-w-[50px] screen1100:rf-w-h-[50px]" />
          </div>
          <p class="rf-flex rf-justify-center screen1100:rf-justify-normal rf-py-2 rf-px-3 rf-text-lg rf-font-bold rf-text-[#0f4379] rf-border-r">
            {{ getCombinedVehicleName(vehicle) }} / {{ vehicle.vehicle_data.year }}
          </p>
          <div class="rf-grid rf-grid-cols-[1fr_1fr] screen1024:rf-grid-cols-[100px_1fr] rf-py-2 rf-px-3 rf-gap-x-3 rf-gap-y-1">
            <div class="rf-flex rf-flex-row rf-border rf-rounded-lg">
              <div class="rf-bg-[#0f4379] rf-text-white rf-px-1 rf-relative rf-flex rf-h-[25px] rf-items-center rf-mr-[14px] rf-rounded-l-lg">
                <span class="item-cell">{{ $t("HP") }}</span>
              </div>
              <div>
                {{ vehicle.vehicle_data.hp }}
              </div>
            </div>
            <div class="rf-flex rf-flex-row rf-border rf-rounded-lg">
              <div class="rf-bg-[#0f4379] rf-text-white rf-px-1 rf-relative rf-flex rf-h-[25px] rf-items-center rf-mr-[14px] rf-rounded-l-lg">
                <span class="item-cell">{{ $t("ECU") }}</span>
              </div>
              <div>
                {{ getCombinedECUDetails(vehicle) }}
              </div>
            </div>
          </div>
        </div>
        <div class="rf-flex rf-flex-row rf-flex-wrap rf-justify-between rf-w-[92%] rf-mx-auto rf-px-3 rf-py-[1px] rf-bg-gray-100 rf-rounded-b rf-shadow-md rf-font-semibold rf-border-t">
          <div class="rf-pr-3">
            <span class="rf-text-[#0f4379] rf-font-normal rf-mr-1">{{ $t("Requested tuning") }}:</span>
            <span class="rf-text-[#FF4800]" v-html="translatedDisplayName(vehicle.tuningDisplayName)"></span>
          </div>
          <div>
            <span class="rf-text-[#0f4379] rf-font-normal rf-mr-1">{{ $t("Credits") }}:</span>
            <span class="rf-text-[#FF4800]">{{ vehicle.credits }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      vehicles: [],
      website_id: 4,
      date_from: "",
      date_until: "",
      limit: 6,
      todayData: null,
      loading: true,
      dateYesterday: "",
      dateUntil: "",
      fileLimit: "",
    };
  },
  created() {
    this.fetchData();
    this.startAutoRefresh();
  },
  methods: {
    async fetchData() {
      this.dateUntil = new Date().toISOString().split("T")[0];
      this.dateYesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0];
      try {
        let params = {
          limit: 10,
        };
        let response = await axios.get("https://crm.ecufiles.com/api/external/files_for_website", {params});

        if (response.data.files.length === 0) {
          this.todayData = false;
          const params = {
            date_from: this.dateYesterday,
            date_until: this.dateUntil,
            limit: 10,
          };
          const responseData = await axios.get("https://crm.ecufiles.com/api/external/files_for_website", {params});
          this.vehicles = responseData.data.files;
        } else {
          this.todayData = true;
          const params = {
            limit: 10,
          };
          const responseData = await axios.get("https://crm.ecufiles.com/api/external/files_for_website", {params});
          this.vehicles = responseData.data.files;
        }
        this.loading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.vehicles = [];
      }
    },
    startAutoRefresh() {
      setInterval(() => {
        this.fetchData();
      }, 600000);
    },
    getBrandImageUrl(brand) {
      const formattedBrand = brand.toLowerCase().replace(/\s+/g, "-");
      return `https://www.ecufiles.com/assets/img/brand-logos/${formattedBrand}.webp`;
    },
    getCombinedVehicleName(vehicle) {
      const combinedName = vehicle.vehicle_data.brand + " " + vehicle.vehicle_data.series + " " + vehicle.vehicle_data.engine;

      return combinedName.length > 30 ? combinedName.slice(0, 30) + "..." : combinedName;
    },
    getCombinedECUDetails(vehicle) {
      const combinedName = JSON.parse(vehicle.meta_hardware).ecu_brand + " " + JSON.parse(vehicle.meta_hardware).ecu_build;

      return combinedName.length > 22 ? combinedName.slice(0, 22) + "..." : combinedName;
    },
    translatedDisplayName(item) {
    let originalText = item;
    let spanMatch = originalText.match(/^(.*?)<span.*?>(.*?)<\/span>$/i);

    if (spanMatch) {
      
      let mainText = spanMatch[1].trim(); 
      let subtitleText = spanMatch[2].trim();

      let translatedMain = this.$t(mainText) || mainText;
      let translatedSubtitle = this.$t(subtitleText) || subtitleText; 

      return translatedMain + `<span class="tuningtype_subtitle" style="margin-left:5px">${translatedSubtitle}</span>`;
    } else {
      return this.$t(originalText) || originalText;
    }
  },
  },
};
</script>
<style scoped>
.grid-cells {
  grid-template-columns: repeat(4, minmax(0, auto));
}

.item-cell::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 25px;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  background: #0f4379;
  bottom: 0;
  right: -11px;
}
</style>
