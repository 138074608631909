import { createI18n } from 'vue-i18n';

// Load translation files
import en from './locales/en.json';
import zh from './locales/zh.json';

// Function to detect user language
const getUserLanguage = () => {
  const lang = navigator.language || navigator.userLanguage;
  return lang.startsWith('zh') ? 'zh' : 'en'; // Default to English if not Chinese
};

// Create i18n instance
const i18n = createI18n({
  legacy: false, // Important for Vue 3 Composition API
  locale: getUserLanguage(), // Set detected language
  fallbackLocale: 'en', // Default to English if translation is missing
  messages: { en, zh } // Available languages
});

export default i18n;